<template>
    <v-autocomplete
      :value="value"
      :filled="editMode"
      :items="items"
      :readonly="editMode"
      color="primaryAccent"
      hide-details
      label="Clinic Name"
      outlined
      dense
      @change="emitSelectedClinicChange"
    >
      <template v-slot:item="data">
        <v-list-item-content>
          <v-list-item-title>{{ data.item.text }}</v-list-item-title>
          <v-list-item-subtitle class="ma-2">
            {{ data.item.description }}
          </v-list-item-subtitle>
        </v-list-item-content>
      </template>
    </v-autocomplete>
</template>

<script>

export default {
  name: "ClinicDropdown",
  components: {
  },
  props: {
    value: {
      required: true,
    },
    editMode: {
      type: Boolean,
      required: false,
      default: false,
    },
    items: {
      required: true,
    },
  },
  data() {
    return {

    }
  },
  created() {
  },
  methods: {
    emitSelectedClinicChange(value){
      this.$emit("selectedClinicChange", value);
    },
  }
}
</script>

<style scoped>

</style>
