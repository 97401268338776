<template>
  <div>
    <mex-dialog :showDialog="showFddDialog" dialogTitle="FORCED DEACTIVATION" width="50%">
      <template v-slot:dialog-content>
        <v-overlay v-if="showLoading">
          <mex-sperm-spinner spinnerText="Updating forced deactivation date" />
        </v-overlay>
        <v-row justify="center">
          <v-col align-self="center" cols="auto">
            <mex-p :content="getWarningHeader" class="mb-0" fontColor="error" fontSize="title" fontWeight="bold" />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-divider />
          </v-col>
        </v-row>
        <!-- Clinic information -->
        <v-row justify="center">
          <v-col align-self="center" cols="6">
            <v-text-field
              v-model="clinicData.name"
              :filled="!editMode"
              :readonly="!editMode"
              color="primaryAccent"
              dense
              hide-details
              label="Clinic name"
              outlined
            />
          </v-col>
          <v-col align-self="center" cols="6">
            <v-text-field v-model="clinicData.clinicUUID" color="primaryAccent" dense filled hide-details label="Clinic UUID" outlined readonly />
          </v-col>
        </v-row>
        <v-row justify="center">
          <v-col align-self="center" cols="4">
            <v-text-field
              v-model="clinicData.meditexActVersion"
              color="primaryAccent"
              dense
              filled
              hide-details
              label="Current MedITEX Version"
              outlined
              readonly
            />
          </v-col>
          <v-col align-self="center" cols="4">
            <v-text-field
              v-model="$dateFormatter.convertJsonDate(clinicData.meditexUpdateDate).date"
              dense
              filled
              hide-details
              label="Updated at"
              outlined
              readonly
            />
          </v-col>
          <v-col cols="4">
            <v-text-field
              v-model="$dateFormatter.convertJsonDate(clinicData.lastContact).full"
              dense
              filled
              hide-details
              label="Last contact at"
              outlined
              readonly
            />
          </v-col>
        </v-row>
        <template>
          <v-row>
            <v-col>
              <v-divider />
            </v-col>
          </v-row>
          <v-row justify="center">
            <v-col cols="auto">
              <v-text-field
                v-model="$dateFormatter.convertJsonDate(clinicData.Locations[0].deactivationDate).full"
                dense
                filled
                hide-details
                label="Dynamic deactivation date"
                outlined
                readonly
              />
            </v-col>
          </v-row>
        </template>
        <template v-if="!isForcedDeactivationDateSet">
          <v-row>
            <v-col>
              <v-divider />
            </v-col>
          </v-row>
          <v-row v-if="!showFddSetter" justify="center">
            <v-col align-self="center" cols="auto">
              <v-icon>mdi-alert</v-icon>
            </v-col>
            <v-col align-self="center" cols="auto">
              <mex-btn content="CHOOSE A FORCED DEACTIVATION DATE" @click="showFddSetter = true" />
            </v-col>
            <v-col align-self="center" cols="auto">
              <v-icon>mdi-alert</v-icon>
            </v-col>
          </v-row>
          <template v-else>
            <v-row justify="center">
              <v-col cols="auto">
                <v-menu
                  ref="menu"
                  v-model="menu"
                  :close-on-content-click="false"
                  :return-value.sync="newForcedDeactivationDate"
                  min-width="auto"
                  offset-y
                  transition="scale-transition"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="newForcedDeactivationDate"
                      v-bind="attrs"
                      v-on="on"
                      dense
                      label="Forced Deactivation Date"
                      outlined
                      readonly
                    ></v-text-field>
                  </template>
                  <v-date-picker v-model="newForcedDeactivationDate" no-title scrollable @change="$refs.menu.save(newForcedDeactivationDate)">
                    </v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
            <v-row justify="center">
              <v-col cols="auto">
                <mex-p :content="getSetWarningText[0]" alignment="center" fontSize="overline" />
                <mex-p :content="getSetWarningText[1]" alignment="center" fontSize="overline" />
              </v-col>
            </v-row>
          </template>
        </template>
        <template v-else>
          <v-row>
            <v-col>
              <v-divider />
            </v-col>
          </v-row>
          <v-row justify="center">
            <mex-sheet color="error" rounded>
              <v-row>
                <v-col>
                  <v-text-field
                    v-model="$dateFormatter.convertJsonDate(clinicData.Locations[0].forcedDeactivationDate).date"
                    dense
                    filled
                    hide-details
                    label="FORCED DEACTIVATION DATE"
                    outlined
                    readonly
                  />
                </v-col>
              </v-row>
            </mex-sheet>
          </v-row>
        </template>
      </template>
      <template slot="dialog-actions">
        <v-row justify="center">
          <v-col v-if="newForcedDeactivationDate" cols="auto">
            <mex-btn content="Set Forced deactivation date" icon="mdi-lan-disconnect" @click="showSetConfirm = true" />
          </v-col>
          <v-col v-else cols="auto">
            <mex-btn content="Unset Forced deactivation date" icon="mdi-lan-connect" @click="showUnsetConfirm = true" />
          </v-col>
          <v-col cols="auto">
            <mex-btn content="Cancel" icon="mdi-close" @click="$emit('reject')" />
          </v-col>
        </v-row>
      </template>
    </mex-dialog>
    <!-- Set confirm overlay -->
    <v-overlay v-if="showSetConfirm" style="z-index: 900">
      <mex-sheet color="error" rounded>
        <v-row justify="center">
          <v-col cols="auto">
            <v-icon>mdi-alert</v-icon>
          </v-col>
          <v-col cols="auto">
            <mex-p content="CONFIRM" fontSize="title" fontWeight="bold" />
          </v-col>
          <v-col cols="auto">
            <v-icon>mdi-alert</v-icon>
          </v-col>
        </v-row>
        <v-row justify="center">
          <v-col cols="auto">
            <mex-p :content="getSetWarningText[0]" alignment="center" fontSize="overline" />
          </v-col>
        </v-row>
        <v-row justify="center">
          <v-col cols="auto">
            <mex-p :content="getSetWarningText[1]" alignment="center" fontSize="overline" />
          </v-col>
        </v-row>
        <v-row justify="center">
          <v-col cols="auto">
            <mex-p content="Please confirm that you want to set the forced deactivation date" fontSize="overline" />
          </v-col>
          <v-col cols="auto">
            <mex-p :content="newForcedDeactivationDate" fontSize="overline" fontWeight="bold-italic" />
          </v-col>
          <v-col cols="auto">
            <mex-p content="for the clinic " fontSize="overline" />
          </v-col>
          <v-col cols="auto">
            <mex-p :content="clinicData.name + ' | ' + clinicData.clinicUUID" fontSize="overline" fontWeight="bold-italic" />
          </v-col>
        </v-row>
        <v-row justify="center">
          <v-col cols="auto">
            <mex-btn content="confirm" @click="updateForcedDeactivationDate" />
          </v-col>
          <v-col cols="auto">
            <mex-btn content="cancel" @click="confirmCanceled" />
          </v-col>
        </v-row>
      </mex-sheet>
    </v-overlay>
    <!-- Unset confirm overlay -->
    <v-overlay v-if="showUnsetConfirm" style="z-index: 900">
      <mex-sheet color="error" rounded>
        <v-row justify="center">
          <v-col cols="auto">
            <v-icon>mdi-alert</v-icon>
          </v-col>
          <v-col cols="auto">
            <mex-p content="CONFIRM" fontSize="title" fontWeight="bold" />
          </v-col>
          <v-col cols="auto">
            <v-icon>mdi-alert</v-icon>
          </v-col>
        </v-row>
        <v-row justify="center">
          <v-col cols="auto">
            <mex-p :content="getUnsetWarningText" alignment="center" fontSize="overline" />
          </v-col>
        </v-row>
        <v-row justify="center">
          <v-col cols="auto">
            <mex-p content="Please confirm that you want to unset the forced deactivation date" fontSize="overline" />
          </v-col>
          <v-col cols="auto">
            <mex-p
              :content="$dateFormatter.convertJsonDate(clinicData.Locations[0].forcedDeactivationDate).date"
              fontSize="overline"
              fontWeight="bold-italic"
            />
          </v-col>
          <v-col cols="auto">
            <mex-p content="for the clinic " fontSize="overline" />
          </v-col>
          <v-col cols="auto">
            <mex-p :content="clinicData.name + ' | ' + clinicData.clinicUUID" fontSize="overline" fontWeight="bold-italic" />
          </v-col>
        </v-row>
        <v-row justify="center">
          <v-col cols="auto">
            <mex-btn content="confirm" @click="updateForcedDeactivationDate" />
          </v-col>
          <v-col cols="auto">
            <mex-btn content="cancel" @click="confirmCanceled" />
          </v-col>
        </v-row>
      </mex-sheet>
    </v-overlay>
  </div>
</template>

<script>
import ClinicsService from '../../services/clinics.service';
import MexDialog from '../MedITEX_Vue_Components/MexComponents/MexDialog.vue';

export default {
  name: 'fddDialog',
  components: { MexDialog },
  props: {
    showFddDialog: {
      type: Boolean,
      default: false,
    },
    clinicData: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      newForcedDeactivationDate: null,
      showFddSetter: false,
      showSetConfirm: false,
      showUnsetConfirm: false,
      showLoading: false,
      editMode: false,
    };
  },
  computed: {
    isForcedDeactivationDateSet() {
      return !!(this.clinicData && this.clinicData.Locations && this.clinicData.Locations[0].forcedDeactivationDate);
    },
    getWarningHeader() {
      if (this.isForcedDeactivationDateSet) {
        return `CURRENTLY A FORCED DEACTIVATION DATE IS SET FOR THE ${
          this.$dateFormatter.convertJsonDate(this.clinicData.Locations[0].forcedDeactivationDate).date
        }`;
      }
      return 'CURRENTLY NO FORCED DEACTIVATION DATE IS SET';
    },
    getSetWarningText() {
      if (this.newForcedDeactivationDate) {
        return [
          `The current dynamically set deactivation date (${
            this.$dateFormatter.convertJsonDate(this.clinicData.Locations[0].deactivationDate).date
          }) will be replaced by the forced deactivation date you selected (${this.newForcedDeactivationDate}).`,
          'The forced deactivation date you selected will not be reset automatically! ',
        ];
      }
      return '';
    },
    getUnsetWarningText() {
      return `The current forced deactivation date (${
        this.$dateFormatter.convertJsonDate(this.clinicData.Locations[0].forcedDeactivationDate).date
      }) will be replaced by the dynamically set deactivation date (${
        this.$dateFormatter.convertJsonDate(this.clinicData.Locations[0].deactivationDate).date
      }).`;
    },
  },
  watch: {
    showFddDialog: {
      handler() {
        this.clearDialog();
      },
    },
  },
  methods: {
    clearDialog() {
      this.newForcedDeactivationDate = null;
      this.showFddSetter = false;
      this.showSetConfirm = false;
      this.showUnsetConfirm = false;
    },
    updateForcedDeactivationDate() {
      this.showSetConfirm = false;
      this.showUnsetConfirm = false;
      this.showLoading = true;
      ClinicsService.updateForcedDeactivationDate(this.clinicData.ClinicID, this.newForcedDeactivationDate)
        .then(() => {
          this.showLoading = false;

          this.$toast.success('Forced deactivation date successfully updated');
          this.confirmCanceled();
        })
        .catch((err) => {
          this.showLoading = false;

          this.$toast.error(err);
          this.confirmCanceled();
        });
    },
    confirmCanceled() {
      this.$emit('reject');
      this.showSetConfirm = false;
      this.showUnsetConfirm = false;
    },
  },
};
</script>

<style></style>
