<template>
  <v-container v-if="hasPermission" fluid>
    <mex-dialog
      dialog-title="Are you sure you want to delete those database-rows?"
      :show-dialog="showAffectedRowsOfDeleteClinic"
      width="50%"
      dialog-color="foreground"
      dialog-accept-button-title="Confirm deletion"
      dialog-reject-button-title="Cancel deletion"
      @accept="authenticateToDeleteClinicData"
      @reject="showAffectedRowsOfDeleteClinic = false"
    >
      <template v-slot:dialog-content>
        <v-row>
          <v-col align-self="center">
            <mex-data-table
              title="Rows affected by deletion of clinic"
              :headers="affectedRowsOfDeleteClinicHeaders"
              :data="affectedRowsOfDeleteClinic"
              :items-per-page="getTablePagination.defaultRowsPerPage"
            >
            </mex-data-table>
          </v-col>
        </v-row>
      </template>
    </mex-dialog>
    <mex-sperm-spinner v-if="clinicViewLoading" :spinnerText="getClinicLoadingSpinnerText" />
    <template v-else>
      <v-row justify="center">
        <v-col cols="12">
          <mex-heading content="Clinic View" />
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-col cols="12">
          <mex-sheet color="background" rounded sheetPadding="2">
            <v-row>
              <v-col align-self="center" cols="4">
                <mex-sheet v-if="showBlacklistBanner" color="error" rounded sheetPadding="1">
                  <v-row justify="start">
                    <v-col align-self="center" cols="auto">
                      <v-icon>mdi-headset-off</v-icon>
                    </v-col>
                    <v-col align-self="center" cols="auto">
                      <mex-p class="mb-0" content="THIS CLINIC IS BLOCKLISTED!" fontWeight="bold" />
                    </v-col>
                    <v-spacer />
                    <v-col cols="auto">
                      <mex-btn icon="mdi-close" iconOnly @click="showBlacklistBanner = false" />
                    </v-col>
                  </v-row>
                </mex-sheet>
              </v-col>
              <v-col cols="4">
                <clinic-dropdown
                  :value="selectedClinic"
                  :items="clinicNames"
                  @selectedClinicChange="selectionChanged">
                </clinic-dropdown>
              </v-col>
              <v-col align-self="center" cols="4">
                <v-row v-if="clinicData && !clinicDataLoading" justify="end">
                  <v-slide-x-transition>
                    <v-col v-if="editMode" cols="1">
                      <v-btn icon @click="saveChanges">
                        <v-icon small>mdi-content-save</v-icon>
                      </v-btn>
                    </v-col>
                  </v-slide-x-transition>
                  <v-slide-x-transition>
                    <v-col v-if="editMode" cols="1">
                      <v-btn icon @click="getClinicViewData">
                        <v-icon small>mdi-undo</v-icon>
                      </v-btn>
                    </v-col>
                  </v-slide-x-transition>
                  <v-col cols="1">
                    <v-btn v-if="writeClinic" icon @click="editMode = true" :color="editMode ? 'primary' : ''">
                      <v-icon small>mdi-pencil</v-icon>
                    </v-btn>
                  </v-col>
                  <v-col v-if="deleteClinic" align-self="center" cols="1">
                    <v-btn icon @click="getAffectedRowsOfDeleteClinic">
                      <v-icon small>mdi-delete</v-icon>
                    </v-btn>
                  </v-col>
                  <v-col align-self="center" cols="1">
                    <v-btn icon @click="getClinicViewData">
                      <v-icon small>mdi-refresh</v-icon>
                    </v-btn>
                  </v-col>
                  <v-col align-self="center" cols="1">
                    <v-btn
                      v-if="createNotes"
                      :color="noteDialogMinimized ? 'note' : ''"
                      :icon="!noteDialogMinimized"
                      content="Open Note"
                      @click="showNoteDialog = !showNoteDialog"
                    >
                      <v-icon small>mdi-file-plus</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </mex-sheet>
        </v-col>
      </v-row>
      <pre-operation-authenticator
        v-if="requestAuthentication"
        operation-description="execute deletion of clinic"
        @abort="requestAuthentication = false"
        @successful-authentication="deleteClinicData"
      ></pre-operation-authenticator>
      <mex-sperm-spinner v-if="clinicDataLoading" :spinnerText="getClinicLoadingSpinnerText" />
      <template v-else>
        <v-row v-if="clinicData && !clinicDataLoading">
          <v-col :cols="contentColumns">
            <v-row>
              <v-col cols="6">
                <expansion-sheet
                  v-if="systemUserData && !clinicViewLoading"
                  color="foreground"
                  rounded
                  class="mb-3"
                >
                  <template v-slot:header>
                    <mex-p content="Clinic Info" fontSize="h5" fontWeight="bold-italic" />
                  </template>
                  <template v-slot:content>
                    <v-row>
                      <v-col cols="6" align-self="center">
                        <v-text-field
                          v-model="clinicData.name"
                          :filled="!editMode"
                          :readonly="!editMode"
                          color="primaryAccent"
                          hide-details
                          dense
                          label="Clinic name"
                          outlined
                        />
                      </v-col>
                      <v-col cols="6" align-self="center">
                        <v-text-field
                          v-model="clinicData.clinicUUID"
                          color="primaryAccent"
                          dense
                          filled
                          hide-details
                          label="Clinic UUID"
                          outlined
                          readonly
                        />
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="6" align-self="center">
                        <v-text-field
                          v-model="clinicData.shortname"
                          :filled="!editMode"
                          :readonly="!editMode"
                          color="primaryAccent"
                          dense
                          hide-details
                          label="Clinic Shortname"
                          outlined
                        />
                      </v-col>
                      <v-col align-self="center" cols="6">
                        <v-text-field
                          v-model="clinicData.macAddress"
                          :filled="!editMode"
                          :readonly="!editMode"
                          dense
                          hide-details
                          label="MAC Address"
                          outlined
                        />
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="6" align-self="center">
                        <v-text-field
                          v-model="clinicData.customerNumber"
                          :filled="!editMode"
                          :readonly="!editMode"
                          color="primaryAccent"
                          dense
                          hide-details
                          label="Customer Number"
                          outlined
                        />
                      </v-col>
                      <v-col cols="6" align-self="center">
                        <v-text-field
                          v-model="$dateFormatter.convertJsonDate(clinicData.meditexBackupTime).full"
                          dense
                          filled
                          hide-details
                          label="Last MedITEX-backup at"
                          outlined
                          readonly
                          :class="{
                            'error-blink': clinicData.checkForMeditexBackups && !meditexBackupTimeInLast24hours
                          }"
                        >
                          <template v-slot:append>
                            <v-icon
                              :color="clinicData.checkForMeditexBackups ? (meditexBackupTimeInLast24hours ? 'success' : 'error') : null"
                            >
                              {{ clinicData.checkForMeditexBackups ? (meditexBackupTimeInLast24hours ? 'mdi-check-decagram' : 'mdi-alert-decagram') : null }}
                            </v-icon>
                          </template>
                        </v-text-field>
                      </v-col>
                    </v-row>
                    <v-row class="align-center">
                      <v-col cols="6">
                        <v-autocomplete
                          v-model="selectedRegisterType"
                          :filled="!editMode"
                          :items="registerTypeNames"
                          :readonly="!editMode"
                          color="primaryAccent"
                          dense
                          hide-details
                          label="Register"
                          outlined
                          @change="registerTypeSelectionChanged"
                        >
                          <template v-slot:item="data">
                            <v-list-item-content>
                              <v-list-item-title>{{ data.item.text }}</v-list-item-title>
                              <v-list-item-subtitle class="ma-2"
                              >{{ data.item.description }}
                              </v-list-item-subtitle>
                            </v-list-item-content>
                          </template>
                        </v-autocomplete>
                      </v-col>
                      <v-col cols="6" align-self="center">
                        <v-text-field
                          v-model="$dateFormatter.convertJsonDate(clinicData.lastContact).full"
                          dense
                          filled
                          hide-details
                          label="Last contact at"
                          outlined
                          readonly
                          :class="{
                            'error-blink': !clinicData.managedManually && !$lastContactEvaluation.lastContactWithin24h(clinicData.lastContact) && !$lastContactEvaluation.lastContactWithin48h(clinicData.lastContact)
                          }"
                          :background-color="clinicData.managedManually
                          ? (getDarkModeSetting ? '#0047AB' : '#488BDB')
                          : ($lastContactEvaluation.lastContactWithin24h(clinicData.lastContact) ? 'success' : (!$lastContactEvaluation.lastContactWithin48h(clinicData.lastContact) ? 'error' : 'warning'))"
                        >
                          <template v-if="editMode" v-slot:append>
                            <div v-if="clinicData.lastContact" class="close-button-wrapper">
                              <mex-btn tooltip='Delete Last-Contact-Date' icon="mdi-close" icon-only v-on="on" v-bind="attrs" @click="showDeleteLastContactDateWarning"/>
                            </div>
                          </template>
                        </v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="6" align-self="center">
                        <v-text-field
                          v-model="getRegisterLocationID"
                          filled
                          readonly
                          color="primaryAccent"
                          dense
                          hide-details
                          label="Center-ID"
                          outlined
                        />
                      </v-col>
                      <v-col cols="6" align-self="center">
                        <v-text-field
                          v-model="periodToDeactivation"
                          color="primaryAccent"
                          dense
                          filled
                          :readonly="!editMode"
                          hide-details
                          label="Period to deactivation (months)"
                          outlined
                          type="number"
                          small
                        />
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="6" align-self="center">
                        <v-text-field
                          v-model="getInfrastructureMeditexVersion"
                          color="primaryAccent"
                          dense
                          filled
                          hide-details
                          label="Current MedITEX Version"
                          outlined
                          small
                          readonly
                        />
                      </v-col>
                      <v-col cols="6" align-self="center">
                        <v-row>
                          <v-col cols="10">
                            <v-text-field
                              v-if="
                                $deactivationStateEvaluation.isForcedDeactivationSet(
                                  clinicData.Locations[0].forcedDeactivationDate
                                )
                              "
                              v-model="
                                $dateFormatter.convertJsonDate(
                                  clinicData.Locations[0].forcedDeactivationDate
                                ).full
                              "
                              dense
                              filled
                              hide-details
                              label="Forced deactivation date"
                              outlined
                              readonly
                              background-color="#DAA520"
                            />
                            <v-text-field
                              v-else
                              v-model="
                                $dateFormatter.convertJsonDate(
                                  clinicData.Locations[0].deactivationDate
                                ).full
                              "
                              dense
                              filled
                              hide-details
                              label="Current deactivation date"
                              outlined
                              readonly
                            />
                          </v-col>
                          <v-col
                            v-if="
                              !$deactivationStateEvaluation.isForcedDeactivationSet(
                                clinicData.Locations[0].forcedDeactivationDate
                              )
                            "
                            align-self="center"
                            cols="1"
                          >
                            <mex-btn
                              v-if="forcedDeactivation"
                              dense
                              icon="mdi-lan-disconnect"
                              iconOnly
                              @click="showFddDialog = !showFddDialog"
                            />
                          </v-col>
                          <v-col v-else align-self="center" cols="1">
                            <mex-btn
                              v-if="forcedDeactivation"
                              dense
                              icon="mdi-lan-connect"
                              iconOnly
                              @click="showFddDialog = !showFddDialog"
                            />
                          </v-col>
                          <v-spacer/>
                        </v-row>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="6" align-self="center">
                        <v-text-field
                          v-model="
                                $dateFormatter.convertJsonDate(getInfrastructureMeditexUpdateDate)
                                  .date
                              "
                          dense
                          filled
                          hide-details
                          label="Updated at"
                          outlined
                          readonly
                        />
                      </v-col>
                    </v-row>
                    <v-row class="narrow-row">
                      <v-col cols="6">
                        <v-checkbox
                          v-model="clinicData.blocklisted"
                          :color="clinicData.blocklisted ? 'error' : 'background'"
                          :disabled="!editMode || !permissionBlocklisted"
                          :label="`${clinicData.blocklisted ? '' : 'Not'} blocklisted`"
                          :hide-details="false"
                        >
                          <template v-slot:label>
                            <span>Blocklisted</span>
                            <span style="padding-right: 10px;"></span>
                            <v-icon v-if="clinicData.blocklisted">mdi-block-helper</v-icon>
                            <v-icon v-else>mdi-message-check-outline</v-icon>
                          </template>
                        </v-checkbox>
                      </v-col>

                      <v-col cols="6">
                        <v-checkbox
                          v-model="!clinicData.notUseBroadcaster"
                          :disabled="!editMode || clinicData.managedManually"
                          @change="checkBroadcasterChange"
                        >
                          <template v-slot:label>
                            <span>SQL Broadcaster is activated</span>
                            <span style="padding-right: 10px;"></span>
                            <v-icon v-if="clinicData.notUseBroadcaster">mdi-database-off</v-icon>
                            <v-icon v-else>mdi-database</v-icon>
                          </template>
                        </v-checkbox>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="6">
                        <v-checkbox
                          v-model="clinicData.managedManually"
                          :color="clinicData.managedManually ? 'primary' : 'background'"
                          :disabled="!editMode"
                          @change="checkManuallyManagedChange"
                        >
                          <template v-slot:label>
                            <span>Manually managed</span>
                            <span style="padding-right: 10px;"></span>
                            <v-icon v-if="clinicData.managedManually">mdi-hand-back-left</v-icon>
                            <v-icon v-else>mdi-hand-back-left-off</v-icon>
                          </template>
                        </v-checkbox>
                      </v-col>

                      <v-col cols="6">
                        <v-checkbox
                          v-model="clinicData.checkForMeditexBackups"
                          :color="clinicData.checkForMeditexBackups ? 'primary' : 'background'"
                          :disabled="!editMode"
                        >
                          <template v-slot:label>
                            <span>MedITEX Backup Check is activated</span>
                            <span style="padding-right: 10px;"></span>
                            <v-icon v-if="clinicData.checkForMeditexBackups">mdi-eye-check</v-icon>
                            <v-icon v-else>mdi-eye-remove</v-icon>
                          </template>
                        </v-checkbox>
                      </v-col>
                    </v-row>
                  </template>
                </expansion-sheet>
                <mex-sheet color="foreground" rounded>
                  <v-row>
                    <v-col cols="auto">
                      <mex-p content="Address" fontSize="h6" fontWeight="bold-italic" />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-expansion-panels>
                      <v-expansion-panel v-for="(address, idx) in clinicData.Addresses" :key="idx">
                        <v-expansion-panel-header color="foreground">
                          {{ address.AddressType.name.toUpperCase() }}
                        </v-expansion-panel-header>
                        <v-expansion-panel-content color="foreground">
                          <address-view
                            :address="address"
                            :clinicID="clinicData.ClinicID"
                            :editMode="editMode"
                            :organizationID="clinicData.OrganizationID"
                          />
                        </v-expansion-panel-content>
                      </v-expansion-panel>
                      <v-expansion-panel v-if="editMode">
                        <v-expansion-panel-header color="foreground">
                          <v-row justify="start">
                            <v-col cols="auto">
                              <v-icon>mdi-map-marker-plus</v-icon>
                            </v-col>
                            <v-col cols="auto">
                              <mex-p class="mb-0" content="Add new address" />
                            </v-col>
                          </v-row>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content color="foreground"></v-expansion-panel-content>
                      </v-expansion-panel>
                    </v-expansion-panels>
                  </v-row>
                </mex-sheet>
                <expansion-sheet color="foreground" rounded :start-expand="true" elevation="2" class="mt-3">
                  <template v-slot:header>
                    <mex-p content="SQL Statements" fontSize="h6" fontWeight="bold-italic"></mex-p>
                  </template>
                  <template v-slot:content>
                    <v-select
                      v-model="selectedSqlStatements"
                      :filled="!editMode"
                      :items="sqlStatementNames"
                      :readonly="!editMode || clinicData.notUseBroadcaster"
                      dense
                      hide-details
                      label="SQL Statements"
                      multiple
                      outlined
                    >
                      <template v-slot:selection="data">
                        <v-tooltip top>
                          <template v-slot:activator="{ attrs, on }">
                            <v-chip
                              :color="data.item.active ? 'primary' : 'grey'"
                              class="ma-1"
                              dense
                              v-bind="attrs"
                              @click="goToSqlStatement(data.item.value)"
                              v-on="on"
                            >
                              {{ data.item.text }}
                            </v-chip>
                          </template>
                          <span>
                                        {{ data.item.description }} |
                                        {{ data.item.active ? "active" : "inactive" }}
                                      </span>
                        </v-tooltip>
                      </template>
                    </v-select>
                  </template>
                </expansion-sheet>
              </v-col>
              <v-col cols="6">
                  <location-overview
                    :clinicID="clinicData.ClinicID"
                    :hideClinicGoTo="true"
                    :hideOrgaGoTo="true"
                    :locations="clinicData.Locations"
                    :organizationID="organizationData.OrganizationID"
                    :wide-table="true"
                    class="mb-3"
                  />
                <expansion-sheet
                  v-if="organizationData && !clinicViewLoading"
                  color="foreground"
                  rounded
                >
                  <template v-slot:header>
                    <v-row>
                      <v-col align-self="center" cols="11">
                        <mex-p
                          class="mb-0"
                          content="Organization"
                          fontSize="h6"
                          fontWeight="bold-italic"
                        />
                      </v-col>
                      <v-col align-self="center" cols="1">
                        <mex-btn
                          v-if="readOrganization"
                          icon="mdi-arrow-right-bold-hexagon-outline"
                          iconOnly
                          @click="goToOrganizationView"
                        />
                      </v-col>
                    </v-row>
                  </template>
                  <template v-slot:content class="mb-1 flex-grow-1">
                    <v-row>
                      <v-col>
                        <v-autocomplete
                          v-model="selectedOrganization"
                          :filled="!editMode"
                          :items="organizationNames"
                          :readonly="!editMode"
                          color="primaryAccent"
                          dense
                          hide-details
                          label="Organization Name"
                          outlined
                          @change="organizationSelectionChanged"
                        >
                          <template v-slot:item="data">
                            <v-list-item-content>
                              <v-list-item-title>{{ data.item.text }}</v-list-item-title>
                              <v-list-item-subtitle class="ma-2"
                              >{{ data.item.description }}
                              </v-list-item-subtitle>
                            </v-list-item-content>
                          </template>
                        </v-autocomplete>
                      </v-col>
                    </v-row>
                  </template>
                </expansion-sheet>
                <ClinicContacts :clinic-id="selectedClinic"/>
                <expansion-sheet
                  v-if="systemUserData && !clinicViewLoading"
                  color="foreground"
                  rounded
                  class="mb-3"
                  :start-expand="false"
                >
                  <template v-slot:header>
                    <mex-p content="DB-Update-Logs" fontSize="h6" fontWeight="bold-italic" />
                  </template>
                  <template v-slot:content class="mb-1 flex-grow-1">
                    <mex-data-table
                      :data="dbUpdateLogs"
                      :headers="dbUpdateLogHeaders"
                      :sort-by="['DbVersion']"
                      :sort-desc="[true]"
                      tableClass="foreground"
                      dense
                      hide-default-footer
                      class="ma-0"
                      :footer-props="{
                              'items-per-page-options': getTablePagination.rowsPerPage,
                            }"
                    >
                      <template v-slot:item.UpdateTime="{ item }">
                        {{ beautifyUpdateTime(item) }}
                      </template>
                    </mex-data-table>
                  </template>
                </expansion-sheet>
                <v-row>
                  <v-col cols="6">
                    <mex-sheet color="foreground" rounded elevation="2">
                      <mex-p content="References" fontSize="h6" fontWeight="bold-italic"></mex-p>
                      <v-row class="mb-2">
                        <v-btn
                          v-if="readOrganization"
                          :disabled="!organizationData || clinicViewLoading"
                          small
                          @click="goToOrganizationView"
                          rounded
                        >
                          <v-icon small class="mr-1">mdi-arrow-right-bold-hexagon-outline</v-icon>
                          Organization Info
                        </v-btn>
                      </v-row>
                      <v-row>
                        <v-btn
                          v-if="readCycleTypesStatistics"
                          :disabled="!clinicData || clinicViewLoading"
                          small
                          @click="goToCycleTypeStatistics"
                          rounded
                        >
                          <v-icon small class="mr-1">mdi-arrow-right-bold-hexagon-outline</v-icon>
                          Cycle Types Statistics
                        </v-btn>
                      </v-row>
                    </mex-sheet>
                  </v-col>
                  <v-col cols="6">
                    <mex-sheet color="foreground" rounded elevation="2">
                      <mex-p content="System Users" fontSize="h6" fontWeight="bold-italic" />
                      <v-select
                        v-model="selectedSystemUsers"
                        :items="systemUserNames"
                        dense
                        filled
                        hide-details
                        label="System-Users"
                        multiple
                        outlined
                        readonly
                      >
                        <template v-slot:selection="data">
                          <v-chip class="ma-1" dense>
                            <v-icon class="mr-1" x-small>mdi-account</v-icon>
                            {{ data.item.text }}
                          </v-chip>
                        </template>
                      </v-select>
                    </mex-sheet>
                  </v-col>
                </v-row>
                <expansion-sheet color="foreground" rounded :start-expand="false" elevation="2" class="mt-3">
                  <template v-slot:header>
                    <mex-p content="Server Infrastructure Information" fontSize="h6" fontWeight="bold-italic"></mex-p>
                  </template>
                  <template v-slot:content>
                    <v-row>
                      <v-col align-self="center" cols="12">
                        <v-textarea
                          :value="infrastructureHDD"
                          :rows="infrastructureHDD ? infrastructureHDD.split('\r\n').length <= 10 ? infrastructureHDD.split('\r\n').length : 10 : 1"
                          dense
                          filled
                          hide-details
                          label="HDD Information"
                          outlined
                          readonly
                        />
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col align-self="center" cols="12">
                        <v-textarea
                          :value="infrastructureMemory"
                          :rows="infrastructureMemory ? infrastructureMemory.split('\r\n').length <= 10 ? infrastructureMemory.split('\r\n').length : 10 : 1"
                          dense
                          filled
                          hide-details
                          label="Memory Information"
                          outlined
                          readonly
                        />
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col align-self="center" cols="12">
                        <v-textarea
                          :value="infrastructureOS"
                          :rows="infrastructureOS ? infrastructureOS.split('\r\n').length <= 10 ? infrastructureOS.split('\r\n').length : 10 : 1"
                          dense
                          filled
                          hide-details
                          label="OS Information"
                          outlined
                          readonly
                        />
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col align-self="center" cols="12">
                        <v-textarea
                          :value="infrastructureSystem"
                          :rows="infrastructureSystem ? infrastructureSystem.split('\r\n').length <= 10 ? infrastructureSystem.split('\r\n').length : 10 : 1"
                          dense
                          filled
                          hide-details
                          label="System Info"
                          outlined
                          readonly
                        />
                      </v-col>
                    </v-row>
                  </template>
                </expansion-sheet>
              </v-col>
            </v-row>
          </v-col>
          <v-col :cols="notesColumns">
            <mex-btn v-if="!notesAreVisible" rounded tooltip="Open Notes" :color="notes.length ? 'primary' : 'foreground'" content="" icon="mdi-note" @click="showNotes"></mex-btn>
            <note-sheet
              v-else-if="systemUserData && !clinicViewLoading && viewNotes"
              :notes="notes"
              show-close-button
              @reloadNotes="getNotes"
              @clickedCloseButton="hideNotes"
            />
          </v-col>
        </v-row>
      </template>
    </template>
    <note-dialog
      v-if="clinicData && !clinicDataLoading"
      key="clinicViewNoteDialog"
      :preSelection="getNotePreSelection"
      :showNoteDialog="showNoteDialog"
      @accept="getNotes"
      @minimize="setNoteDialogMinimized"
      @reject="setNoteDialogDiscard"
    />
    <forced-deactivation-date-dialog
      :clinicData="clinicData"
      :showFddDialog="showFddDialog"
      @reject="getClinicViewData"
    />
    <v-dialog persistent v-model="showGenericDialog" max-width="500px">
      <v-card>
        <v-card-title>
          {{ genericDialog.title }}
        </v-card-title>
        <v-card-text class="text-h5">
          {{ genericDialog.text }}
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <!-- call of function with generic name but - if overwritten - specific content,
           for more information see documentation in data-property -->
          <mex-btn v-if="genericDialog.okClick" content="OK" text @click="genericDialog.okClick()"></mex-btn>
          <v-spacer v-if="genericDialog.okClick && genericDialog.cancelClick"></v-spacer>
          <!-- call of function with generic name but - if overwritten - specific content,
           for more information see documentation in data-property -->
          <mex-btn v-if="genericDialog.cancelClick" content="CANCEL" text @click="genericDialog.cancelClick()"></mex-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <save-request :showSaveRequest="showSaveRequest" @closeSaveRequest="showSaveRequest = false" />
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import SaveRequest from "../../components/LicSrvComponents/SaveRequest.vue";
import AddressView from "../../components/LicSrvComponents/AddressView.vue";
import LocationOverview from "../../components/LicSrvComponents/LocationOverview.vue";
import NoteDialog from "../../components/LicSrvComponents/NoteDialog.vue";
import ClinicsService from "../../services/clinics.service";
import ClinicSettingsService from "../../services/clinicSettings.service";
import NotesService from "../../services/notes.service";
import OrganizationsService from "../../services/organizations.service";
import SystemUsersService from "../../services/systemUsers.service";
import NoteSheet from "../../components/LicSrvComponents/NoteSheet.vue";
import ForcedDeactivationDateDialog from "../../components/LicSrvComponents/ForcedDeactivationDateDialog.vue";
import ExpansionSheet from "../../components/LicSrvComponents/ExpansionSheet.vue";
import RegisterTypesService from "../../services/registerTypes.service";
import SqlStatementsService from "../../services/sqlStatements.service";
import keyListenerManager from "../../functions/keyListenerManager";
import requiredPermissions from "../../requiredPermissions";
import MexStatusLamp from "../../components/MedITEX_Vue_Components/MexComponents/MexStatusLamp";
import PreOperationAuthenticator from "../../components/LicSrvComponents/PreOperationAuthenticatorOverlay";
import MexDialog from "../../components/MedITEX_Vue_Components/MexComponents/MexDialog";
import MexDataTable from "../../components/MedITEX_Vue_Components/MexComponents/MexDataTable";
import MexBtn from "../../components/MedITEX_Vue_Components/MexComponents/MexButton";
import getTablePagination from "../../config/tablePagination.config";
import {assignPropertyIfExists} from "../../functions/assignPropertyIfExists";
import ClinicContacts from "../../components/LicSrvComponents/ClinicContacts";
import {compareSemanticVersionWithUnderscoreSuffix} from '../../functions/compareVersions';
import DbUpdateLogsService from "../../services/dbUpdateLogs.service";
import ClinicDropdown from "../../components/LicSrvComponents/ClinicDropdown.vue";

export default {
  name: "ClinicView",
  components: {
    ClinicDropdown,
    MexBtn,
    MexDataTable,
    MexDialog,
    PreOperationAuthenticator,
//    MexDialog,
    MexStatusLamp,
    LocationOverview,
//    Login2FA,
    AddressView,
    NoteDialog,
    NoteSheet,
    ForcedDeactivationDateDialog,
    ExpansionSheet,
    SaveRequest,
    ClinicContacts,
  },
  computed: {
    ...mapGetters('theme', ['getDarkModeSetting']),
    getRegisterLocationID() {
      return this.clinicData.Locations && this.clinicData.Locations[0].registerLocationID ? this.clinicData.Locations[0].registerLocationID : null;
    },
    getTablePagination() {
      return getTablePagination
    },
    ...mapGetters("sysAuth", ["getUserPermissions"]),
    writeClinic() {
      return this.getUserPermissions.includes(
        "write_Customer Administration_Clinic Administration"
      );
    },
    deleteClinic() {
      return this.getUserPermissions.includes(
        "delete_Customer Administration_Clinic Administration"
      );
    },
    readSQLStatement() {
      return this.getUserPermissions.includes("read_SQL Broadcaster");
    },
    forcedDeactivation() {
      return this.getUserPermissions.includes(
        "_Customer Administration_Clinic Administration_Forced Deactivation"
      );
    },
    readStatistics() {
      return (
        this.getUserPermissions.includes("_Statistics_Cycle Types") ||
        this.getUserPermissions.includes("_Statistics_Endpoints") ||
        this.getUserPermissions.includes("_Statistics_Global Statistics")
      );
    },
    readCycleTypesStatistics() {
      return this.getUserPermissions.includes("_Statistics_Cycle Types");
    },
    readEndpointsStatistics() {
      return this.getUserPermissions.includes("_Statistics_Endpoints");
    },
    readGlobalStatistics() {
      return this.getUserPermissions.includes("_Statistics_Global Statistics");
    },
    readOrganization() {
      return this.getUserPermissions.includes(
        "read_Customer Administration_Organization Administration"
      );
    },
    createNotes() {
      return this.getUserPermissions.includes("create_Customer Administration_Note");
    },
    viewNotes() {
      return this.getUserPermissions.includes("view_Customer Administration_Note");
    },
    enableBlocklistClinic() {
      return this.getUserPermissions.includes(
        "enable_Customer Administration_Clinic Administration_Blocklisted"
      );
    },
    disableBlocklistClinic() {
      return this.getUserPermissions.includes(
        "disable_Customer Administration_Clinic Administration_Blocklisted"
      );
    },
    getClinicLoadingSpinnerText() {
      return this.clinicViewLoadingText;
    },
    getNotePreSelection() {
      return {
        organization: this.organizationData.OrganizationID,
        clinic: this.selectedClinic,
        location: null,
        systemUsers: this.selectedSystemUsers
      };
    },
    getInfrastructureData() {
      return this.clinicData.Infrastructures[0] ? this.clinicData.Infrastructures[0] : null;
    },
    getInfrastructureHDD() {
      // eslint-disable-next-line no-nested-ternary
      return this.getInfrastructureData
        ? this.getInfrastructureData.serverHDD
          ? this.getInfrastructureData.serverHDD
          : null
        : null;
    },
    getInfrastructureMemory() {
      // eslint-disable-next-line no-nested-ternary
      return this.getInfrastructureData
        ? this.getInfrastructureData.serverMemory
          ? this.getInfrastructureData.serverMemory
          : null
        : null;
    },
    getInfrastructureOS() {
      // eslint-disable-next-line no-nested-ternary
      return this.getInfrastructureData
        ? this.getInfrastructureData.serverOS
          ? this.getInfrastructureData.serverOS
          : null
        : null;
    },
    getInfrastructureSystem() {
      // eslint-disable-next-line no-nested-ternary
      return this.getInfrastructureData
        ? this.getInfrastructureData.serverSystemInfo
          ? this.getInfrastructureData.serverSystemInfo
          : null
        : null;
    },
    getInfrastructureMeditexVersion() {
      // eslint-disable-next-line no-nested-ternary
      return this.getInfrastructureData
        ? this.getInfrastructureData.meditexVersion
          ? this.getInfrastructureData.meditexVersion
          : null
        : null;
    },
    getInfrastructureMeditexUpdateDate() {
      // eslint-disable-next-line no-nested-ternary
      return this.getInfrastructureData
        ? this.getInfrastructureData.meditexUpdateDate
          ? this.getInfrastructureData.meditexUpdateDate
          : null
        : null;
    },
    meditexBackupTimeInLast24hours() {
      const currentTime = new Date();
      const backupDate = new Date(this.clinicData.meditexBackupTime);
      return (currentTime - backupDate) <= 24 * 60 * 60 * 1000;
    },
    permissionBlocklisted() {
      return this.clinicData.blocklisted ? this.disableBlocklistClinic : this.enableBlocklistClinic;
    }
  },
  data() {
    return {
      // visualization
      clinicViewLoadingText: "",
      clinicViewLoading: false,
      clinicDataLoading: false,
      showNoteDialog: false,
      noteDialogMinimized: false,
      showFddDialog: false,
      showSaveRequest: false,
      showBlacklistBanner: false,
      // data
      organizationNames: [],
      selectedOrganization: "",
      organizationData: null,
      registerTypeNames: [],
      selectedRegisterType: "",
      registerTypeData: null,
      clinicNames: [],
      selectedClinic: "",
      clinicData: null,
      systemUserData: null,
      selectedSystemUsers: [],
      systemUserNames: [],
      clinicName: "",
      notes: [],
      sqlStatementNames: [],
      selectedSqlStatements: [],
      showAffectedRowsOfDeleteClinic: false,
      affectedRowsOfDeleteClinic: [],
      affectedRowsOfDeleteClinicHeaders: [
        {
          text: 'Name of module',
          sortable: true,
          value: 'modelName',
        },
        {
          text: 'Count of affected rows',
          sortable: true,
          value: 'affectedRowCount',
        },
      ],
      clinicSettings: null,
      periodToDeactivation: 0,
      // edit
      editMode: false,
      editModeAndSaveShortCutListener: null,
      boundEditModeAndSaveShortCutListener: null,
      hasPermission: false,
      showGenericDialog: false,
      lastContactChanged: false,

      // the following 4 properties are supposed to make the warning-dialog (v-model "showGenericDialog") generic:

      genericDialog: {
        // function which gets called on click of OK-button of warning dialog: can be overwritten for
        // specific use cases, then afterwards to not mess with other functions it's supposed to be set
        // back to the "genericDialog.defaultOkClick"
        okClick: () => this.showGenericDialog = false,
        // function which gets called on click of CANCEL-button of warning dialog: can be overwritten for
        // specific use cases, then afterwards to not mess with other functions it's supposed to be set
        // back to the "genericDialog.defaultCancelClick"
        cancelClick: () => this.showGenericDialog = false,
        // default function which is used to set-back genericDialog.okClick after being overwritten,
        // IS NOT SUPPOSED TO BE CHANGED
        defaultOkClick: () => this.showGenericDialog = false,
        // default function which is used to set-back genericDialog.cancelClick after being overwritten,
        // IS NOT SUPPOSED TO BE CHANGED
        defaultCancelClick: () => this.showGenericDialog = false,
        text: '',
        title: 'Warning',
      },

      // authentication
      requestAuthentication: false,
      isReload: false,

      // clinic infrastructure info
      infrastructureHDD: '',
      infrastructureMemory: '',
      infrastructureOS: '',
      infrastructureSystem: '',

      // db-update-log-info
      showDbUpdateLogs: false,
      dbUpdateLogs: null,
      dbUpdateLogHeaders: [
        {
          text: 'Database-Version',
          align: 'start',
          sortable: true,
          value: 'DbVersion',
          sort: compareSemanticVersionWithUnderscoreSuffix,
        },
        {
          text: 'Update-Time',
          align: 'start',
          sortable: true,
          value: 'UpdateTime'
        },
      ],
      notesAreVisible: false,
      notesColumns: 1,
      contentColumns: 11,
    };
  },
  methods: {
    handleCheckboxClick(flagToChange, event) {
      if (this.editMode) {
        this.clinicData[flagToChange] = !this.clinicData[flagToChange];
      } else {
        event.preventDefault();
      }
    },
    showNotes() {
      this.notesAreVisible = true;
      this.notesColumns = 3;
      this.contentColumns = 9;
    },
    hideNotes() {
      this.notesAreVisible = false;
      this.notesColumns = 1;
      this.contentColumns = 11;
    },
    beautifyUpdateTime(item) {
      const updateTime = new Date(item.UpdateTime);
      return updateTime.toLocaleDateString(
        'de-DE',
        {
          year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit'
        }
      );
    },
    reset() {
      Object.assign(this.$data, this.$options.data.apply(this));
    },
    authenticateToDeleteClinicData() {
      this.showAffectedRowsOfDeleteClinic = false;
      this.requestAuthentication = true;
    },
    getAffectedRowsOfDeleteClinic() {
      this.clinicViewLoadingText = "collect data to prepare deletion";
      this.clinicDataLoading = true;
      ClinicsService.getAffectedRowsOfDeleteClinic(this.selectedClinic)
        .then((res) => {
          this.clinicDataLoading = false;
          this.affectedRowsOfDeleteClinic = res.data.affectedRows;
          this.showAffectedRowsOfDeleteClinic = true;
        })
        .catch((err) => {
          this.clinicDataLoading = false;
          this.$toast.error('Couldn\'t get affected rows of delete clinic');
        });
    },
    deleteClinicData() {
      this.requestAuthentication = false;
      this.clinicViewLoadingText = "deleting clinic";
      this.clinicDataLoading = true;
      ClinicsService.deleteClinicData(this.selectedClinic)
        .then(async () => {
          this.reset();
          this.isReload = true;
          await this.initialSetup();
          this.isReload = false;
          this.clinicDataLoading = false;
          this.$toast.success('Successfully removed clinic');
        })
        .catch((err) => {
          this.clinicDataLoading = false;
          this.$toast.error('Couldn\'t delete clinic');
        });
    },
    selectionChanged(value) {
      this.selectedClinic = value;
    },
    organizationSelectionChanged(value) {
      this.selectedOrganization = value;
    },
    registerTypeSelectionChanged(value) {
      this.selectedRegisterType = value;
    },
    loadInfrastructureProperties() {
      // extend this array with future infrastructure-properties if necessary
      [ 'HDD', 'Memory', 'OS', 'System' ].forEach((infrastructureProperty) => {
        this['infrastructure' + infrastructureProperty] = this.cutSystemInfoHeader(this['getInfrastructure' + infrastructureProperty]);
      });
    },
    getClinicViewData() {
      this.showFddDialog = false;
      this.editMode = false;
      this.clinicDataLoading = true;
      this.clinicViewLoadingText = "loading clinic data";
      // ---------------- GET THE CLINIC DATA -------------------
      ClinicsService.getClinicData(this.selectedClinic)
        .then(clinicResponse => {
          this.clinicData = clinicResponse.data;
          this.selectedSqlStatements = this.clinicData.SqlStatementsClinics.map(
            x => x.SqlStatementID
          );
          this.showBlacklistBanner = this.clinicData.blocklisted;
          this.clinicViewLoadingText = "loading organization data";
          this.lastContactChanged = false;
          this.loadInfrastructureProperties();
          DbUpdateLogsService.getDbUpdateLogsOfClinic(this.selectedClinic)
            .then((dbUpdateLogsResponse) => {
              this.dbUpdateLogs = dbUpdateLogsResponse.data.dbUpdateLogs;
              ClinicSettingsService.getClinicSettingsOfClinic(this.selectedClinic)
                .then((clinicSettings) => {
                  this.clinicSettings = clinicSettings.data.clinicSettings;
                  this.periodToDeactivation = this.clinicSettings ? this.clinicSettings.periodToDeactivation : null;
                  // ---------------- GET THE ORGANIZATION DATA -------------------
                  OrganizationsService.getOrganizationData(this.clinicData.OrganizationID)
                    .then(organizationResponse => {
                      this.organizationData = organizationResponse.data;
                      // ---------------- GET THE OTHER ORGANIZATION NAMES -------------------
                      OrganizationsService.getOrganizationNames()
                        .then(organizationNameResponse => {
                          organizationNameResponse.data.forEach(orga => {
                            this.organizationNames.push({
                              value: orga.OrganizationID,
                              text: orga.name,
                              description: orga.customerNumber
                            });
                          });
                          // set the assigned organization as selected organization
                          this.selectedOrganization = this.organizationData.OrganizationID;
                          this.clinicViewLoadingText = "loading register type data";
                          RegisterTypesService.getRegisterTypeNames()
                            .then(registerTypesResponse => {
                              registerTypesResponse.data.forEach(registerType => {
                                this.registerTypeNames.push({
                                  value: registerType.RegisterTypeID,
                                  text: `${registerType.acronym} | ${registerType.name}`,
                                  description: registerType.description
                                });
                              });
                              this.selectedRegisterType = this.clinicData.RegisterTypeID;
                              this.clinicViewLoadingText = "loading system user data";
                              // ---------------- GET THE SYSTEM USERS DATA -------------------
                              SystemUsersService.getSystemUsersByOrganization(
                                this.organizationData.OrganizationID
                              )
                                .then(systemUsersResponse => {
                                  this.systemUserData = systemUsersResponse.data;
                                  this.selectedSystemUsers = [];
                                  this.systemUserData.forEach(systemUser => {
                                    this.selectedSystemUsers.push(systemUser.SystemUserID);
                                  });
                                  // ---------------- GET THE NOTES DATA -------------------
                                  this.clinicViewLoadingText = "loading notes";
                                  this.getNotes();
                                })
                                .catch(err => {
                                  this.clinicDataLoading = false;
                                  this.$toast.error(err.response.data);
                                });
                            })
                            .catch(err => {
                              this.clinicDataLoading = false;
                              this.$toast.error(err.response.data);
                            });
                        })
                        .catch(err => {
                          this.clinicDataLoading = false;
                          this.$toast.error(err.response.data);
                        });
                    })
                    .catch(err => {
                      this.clinicDataLoading = false;
                      this.$toast.error(err.response.data);
                    });
                })
                .catch(err => {
                  this.clinicDataLoading = false;
                  this.$toast.error(err.response.data);
                });
            })
            .catch((err) => {
              this.clinicDataLoading = false;
              this.$toast.error('Problem while loading DB-Update-Logs for this clinic');
            });
        })
        .catch(err => {
          this.clinicDataLoading = false;
          this.$toast.error(err.response.data);
        });
    },
    getNotes() {
      this.showNoteDialog = false;
      NotesService.getNotes(this.organizationData.OrganizationID, this.clinicData.ClinicID, "-1")
        .then(notesResponse => {
          this.notes = notesResponse.data;
          this.clinicDataLoading = false;
        })
        .catch(err => {
          this.clinicDataLoading = false;
          this.$toast.error(err.response.data);
        });
    },
    async getSelectData() {
      try {
        const clinicResponse = await ClinicsService.getCompressedClinicOverview();
        clinicResponse.data.forEach(clinic => {
          this.clinicNames.push({
            value: clinic.ClinicID,
            text: `${clinic.name} / ${clinic.clinicUUID} ${clinic.shortname? " / " + clinic.shortname : ''} / ${clinic.customerNumber}`,
            description: clinic.Organization.name
          });
        });
        const systemUserResponse = await SystemUsersService.getSystemUserNames();
        systemUserResponse.data.forEach(systemUser => {
          this.systemUserNames.push({
            value: systemUser.SystemUserID,
            text: systemUser.username
          });
        });
        await this.fetchSqlStatementNames();
      } catch(error) {
        this.$toast.error(error.response.data);
      }
    },
    async fetchSqlStatementNames() {
      this.sqlStatementNames = [];
      try {
        const sqlStatementResponse = await SqlStatementsService.getSqlStatementNames()
        sqlStatementResponse.data.forEach(statement => {
          this.sqlStatementNames.push({
            value: statement.SqlStatementID,
            text: statement.name,
            description: statement.description,
            active: statement.active
          });
        });

        if (this.preSelection && this.preSelection.selectedSqlStatement) {
          this.addMode = false;
          this.selectedSqlStatement = this.preSelection.selectedSqlStatement;
        }
      } catch(error) {
        this.$toast.error(error.response.data);
      }
    },
    fetchCurrentUserPermissions() {
      SystemUsersService.getCurrentUserPermissions().then(response => {
        requiredPermissions.updatePermissions(
          this.getUserPermissions,
          response.data.permissions,
          this.$store
        );
        if (
          !requiredPermissions.checkRequiredPermissions(
            requiredPermissions.clinicAdministration,
            response.data.permissions
          )
        ) {
          this.$router.push({ name: "NotFound" });
        } else {
          this.hasPermission = true;
        }
        if (
          response.data.permissions.includes("write_Customer Administration_Clinic Administration")
        ) {
          this.manageKeyListener();
        }
      });
    },
    saveChanges() {
      ClinicsService.updateClinicData(
        this.clinicData.ClinicID,
        this.clinicData.name,
        this.clinicData.shortname,
        this.selectedOrganization,
        this.selectedRegisterType,
        this.clinicData.customerNumber,
        this.clinicData.blocklisted,
        this.selectedSqlStatements,
        this.clinicData.macAddress,
        this.clinicData.notUseBroadcaster,
        this.clinicData.managedManually,
        this.clinicData.checkForMeditexBackups,
        this.lastContactChanged,
      )
        .then(clinicResponse => {
          ClinicSettingsService.updateClinicSettingData(this.selectedClinic, this.periodToDeactivation)
            .then(() => {
              this.$toast.success("Update successful");
              this.editMode = false;
              this.$router.push({
                name: "ParamsReload",
                params: { name: "ClinicView", reloadParams: { id: clinicResponse.data.ClinicID } }
              });
            })
            .catch((err) => {
              this.$toast.error(err.response.data);
            });
        })
        .catch(err => {
          this.$toast.error(err.response.data);
        });
    },
    setNoteDialogDiscard() {
      this.showNoteDialog = false;
      this.noteDialogMinimized = false;
    },
    setNoteDialogMinimized() {
      this.showNoteDialog = false;
      this.noteDialogMinimized = true;
    },
    goToOrganizationView() {
      this.$router.push({
        name: "OrganizationView",
        params: { id: this.organizationData.OrganizationID }
      });
    },
    goToSqlStatement(sqlStatementID) {
      if (this.readSQLStatement) {
        this.$router.push({ name: "SqlStatementEditor", params: { id: sqlStatementID } });
      }
    },
    goToCycleTypeStatistics() {
      this.$router.push({
        name: "ClinicCycleTypesStatistics",
        params: {
          organizationID: this.organizationData.OrganizationID,
          clinicID: this.clinicData.ClinicID,
          startDate: "2010-01-01"
        }
      });
    },
    goToEndpointsStatistics() {
      this.$router.push({
        name: "ClinicEndpointsStatistics",
        params: {
          organizationID: this.organizationData.OrganizationID,
          clinicID: this.clinicData.ClinicID
        },
        startDate: "2010-01-01"
      });
    },
    goToGlobalStatistics() {
      this.$router.push({
        name: "GlobalStatistics",
        params: {}
      });
    },
    goToManagedManuallyClinicView() {
      this.$router.push({
        name: "ClinicViewManuallyManaged",
        params: { id: this.clinicData.ClinicID },
      });
    },
    cutSystemInfoHeader(str) {
      if (!str) {
        return null;
      }
      const stringArray = str.substr(str.indexOf(":#") + 1, str.length).split("#'");
      // if the first element of the string-array is an empty string, we remove it
      stringArray.length && stringArray[0] === '' && stringArray.shift();
      return stringArray.join("\r\n");
    },
    manageKeyListener() {
      keyListenerManager.editModeAndSaveShortCut(
        this,
        document,
        "editModeAndSaveShortCutListener",
        "boundEditModeAndSaveShortCutListener",
        "editMode",
        "getClinicViewData",
        "saveChanges"
      );
    },
    checkManuallyManagedChange() {
      this.genericDialog.text = 'Warning';
      this.genericDialog.okClick = this.genericDialog.defaultOkClick;
      this.showGenericDialog = true;
      if (this.clinicData.managedManually) {
        const isBroadcasterEnabled = this.clinicData.notUseBroadcaster;
        this.clinicData.notUseBroadcaster = true;
        this.genericDialog.cancelClick = () => {
          this.clinicData.notUseBroadcaster = isBroadcasterEnabled;
          this.clinicData.managedManually = !this.clinicData.managedManually;
          this.genericDialog.defaultCancelClick();
        }
        this.genericDialog.text = 'If you select "Clinic is manually managed", all connections from this clinic will be blocked! All assignments to SQL Statements and their results will be deleted!';
      } else {
        this.genericDialog.cancelClick = () => { this.clinicData.managedManually = !this.clinicData.managedManually; this.genericDialog.defaultCancelClick(); }
        this.genericDialog.text = 'SQL Broadcaster is disabled for this clinic, all assignments to SQL Statements and their results will be deleted! If you do not want this, please enable it!';
      }
    },
    showDeleteLastContactDateWarning() {
      this.genericDialog.okClick = () => { this.lastContactChanged = true; this.clinicData.lastContact = null; this.genericDialog.defaultOkClick(); };
      this.genericDialog.cancelClick = this.genericDialog.defaultCancelClick;
      this.showGenericDialog = true;
      this.genericDialog.text = 'Warning';
      this.genericDialog.text = 'If you delete the "Last Contact Date", all Cycle Statistic data of the timespan from 01.01.2017 until today will be resent and updated. Continue anyway?';
    },
    checkBroadcasterChange() {
      this.clinicData.notUseBroadcaster = !this.clinicData.notUseBroadcaster;
      if (this.clinicData.notUseBroadcaster) {
        this.genericDialog.text = 'Warning';
        this.genericDialog.text = 'If you disable SQL broadcaster, all assignments to SQL Statements and their results will be deleted!';
        this.genericDialog.okClick = this.genericDialog.defaultOkClick;
        this.genericDialog.cancelClick = this.genericDialog.defaultCancelClick;
        this.showGenericDialog = true;
      }
    },
    /**
     * sets up page
     *
     * @param isAReload - tells if it's an actual created-call (isAReload = false) or if it's called because the page is reloaded by the developer (isAReload = true)
     */
    async initialSetup() {
      try {
        const hasPermission = await this.$userPermissions.fetchCurrentUserPermissions(requiredPermissions.clinicAdministration, this.$store);
        if (hasPermission) {
          this.hasPermission = true;
          if (this.getUserPermissions.includes("write_Customer Administration_Clinic Administration")) {
            this.manageKeyListener();
          }
          await this.getSelectData();
          this.selectedClinic = assignPropertyIfExists([this.$route.params, this.$route.query], 'id', this.$store, 'clinicView', 'setClinicView');
        } else {
          this.$router.push({ name: "NotFound" });
        }
      } catch(error) {
        this.$router.push({ name: "NotFound" });
      }
    }
  },
  watch: {
    selectedClinic: {
      handler() {
        if (!this.isReload) {
          this.getClinicViewData();
          this.$store.commit("selectedProperties/setClinicView", this.selectedClinic);
        }
      }
    },
  },
  beforeDestroy() {
    document.removeEventListener("keydown", this.boundEditModeAndSaveShortCutListener);
  },
  created() {
    this.initialSetup();
  },
  beforeRouteLeave(to, from, next) {
    if (this.editMode && !this.showSaveRequest) {
      this.showSaveRequest = true;
    } else {
      next();
    }
  }
};
</script>
<style scoped>
.close-button-wrapper {
  position: relative;
  top: -25px; /* adjust as necessary */
  right: -36px; /* adjust as necessary */
}
@keyframes blink {
  0%, 100% {
    opacity: 0.6;
  }
  50% {
    opacity: 2;
  }
}

.error-blink {
  animation: blink 2s infinite;
}

.narrow-row {
  margin-top: -20px !important;
  margin-bottom: -50px !important;
}
</style>
